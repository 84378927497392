<script setup></script>

<template>
  <div
    class="zalo-chat-widget"
    data-oaid="4421520053658514179"
    data-welcome-message="QRX Rất vui khi được hỗ trợ bạn!"
    data-autopopup="0"
    data-width=""
    data-height=""
  ></div>
</template>
