import { default as bang_45giaP4n22KXNrcMeta } from "/home/runner/work/mirascan/mirascan/pages/bang-gia.vue?macro=true";
import { default as forgot_45passwordIyouYsgunfMeta } from "/home/runner/work/mirascan/mirascan/pages/forgot-password.vue?macro=true";
import { default as giai_45phapcy88KZjepGMeta } from "/home/runner/work/mirascan/mirascan/pages/giai-phap.vue?macro=true";
import { default as ho_45troh26ze7s6YwMeta } from "/home/runner/work/mirascan/mirascan/pages/ho-tro.vue?macro=true";
import { default as indexAXib01Ba2rMeta } from "/home/runner/work/mirascan/mirascan/pages/index.vue?macro=true";
import { default as loginE3sFmGIb9JMeta } from "/home/runner/work/mirascan/mirascan/pages/login.vue?macro=true";
import { default as not_45loginIPrT5DA1d4Meta } from "/home/runner/work/mirascan/mirascan/pages/not-login.vue?macro=true";
import { default as qrcode_45bao_45hanhMoE6h4AJDsMeta } from "/home/runner/work/mirascan/mirascan/pages/qrcode-bao-hanh.vue?macro=true";
import { default as qrcode_45chong_45giaHdA7kL1chGMeta } from "/home/runner/work/mirascan/mirascan/pages/qrcode-chong-gia.vue?macro=true";
import { default as qrcode_45loyaltyE1bbtiiJ5iMeta } from "/home/runner/work/mirascan/mirascan/pages/qrcode-loyalty.vue?macro=true";
import { default as qrcode_45marketingwBiwoehLmoMeta } from "/home/runner/work/mirascan/mirascan/pages/qrcode-marketing.vue?macro=true";
import { default as register5KgyhXZBPqMeta } from "/home/runner/work/mirascan/mirascan/pages/register.vue?macro=true";
import { default as tao_45ma_45qrwVZ07vqDORMeta } from "/home/runner/work/mirascan/mirascan/pages/tao-ma-qr.vue?macro=true";
import { default as ve_45qrxFaUl9zhifnMeta } from "/home/runner/work/mirascan/mirascan/pages/ve-qrx.vue?macro=true";
import { default as zalo_45mini_45appJfQLYgglnhMeta } from "/home/runner/work/mirascan/mirascan/pages/zalo-mini-app.vue?macro=true";
export default [
  {
    name: "bang-gia",
    path: "/bang-gia",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/bang-gia.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap",
    path: "/giai-phap",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/giai-phap.vue").then(m => m.default || m)
  },
  {
    name: "ho-tro",
    path: "/ho-tro",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/ho-tro.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "not-login",
    path: "/not-login",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/not-login.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-bao-hanh",
    path: "/qrcode-bao-hanh",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/qrcode-bao-hanh.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-chong-gia",
    path: "/qrcode-chong-gia",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/qrcode-chong-gia.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-loyalty",
    path: "/qrcode-loyalty",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/qrcode-loyalty.vue").then(m => m.default || m)
  },
  {
    name: "qrcode-marketing",
    path: "/qrcode-marketing",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/qrcode-marketing.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "tao-ma-qr",
    path: "/tao-ma-qr",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/tao-ma-qr.vue").then(m => m.default || m)
  },
  {
    name: "ve-qrx",
    path: "/ve-qrx",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/ve-qrx.vue").then(m => m.default || m)
  },
  {
    name: "zalo-mini-app",
    path: "/zalo-mini-app",
    component: () => import("/home/runner/work/mirascan/mirascan/pages/zalo-mini-app.vue").then(m => m.default || m)
  }
]